import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = ({ title }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title || 'Raouf Ferkous - Videographer & Filmmaker'; // Set default title if none is provided
  }, [location, title]);

  return null; // This component doesn't render anything
};

export default PageTitle;
