import { useState, useEffect, useRef, useCallback } from 'react';
import { useIsVisible } from '../../shared/useIsVisible';
import Loader from '../loader';
import { motion } from 'framer-motion';
import './video.css';

export const VideoComponent = ({
  src,
  poster,
  style,
  alt,
  showControls,
  isMuted = true,
  isLandscape = false,
}) => {
  const { isVisible, targetRef } = useIsVisible(
    {
      root: null,
      rootMargin: '200px',
      threshold: 0.1,
    },
    false
  );

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const startVideo = useCallback(() => {
    if (videoRef.current && isVideoReady) {
      videoRef.current.muted = isMuted; // Ensure video is muted for autoplay
      videoRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error('Error playing video:', error);
        });
    }
  }, [isMuted, isVideoReady]);

  const stopVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, []);

  useEffect(() => {
    if (isVisible && isVideoReady) {
      startVideo();
    } else {
      stopVideo();
    }
  }, [isVisible, isVideoReady, startVideo, stopVideo]);

  // Ensure the video is ready to play before attempting to play it
  const handleCanPlay = () => {
    setIsVideoReady(true);
  };

  // Clear the video state when component unmounts or when video element is removed
  useEffect(() => {
    return () => {
      setIsVideoReady(false); // Reset video readiness
      stopVideo(); // Ensure video is paused on unmount
    };
  }, [stopVideo]);

  return (
    <span ref={targetRef} className="videoWrapper">
      {!isVisible && <Loader />}
      <video
        ref={videoRef}
        loop
        muted={isMuted} // Ensure autoplay works by muting initially
        autoPlay
        preload="auto"
        playsInline
        poster={poster}
        aria-label={alt}
        style={{
          objectFit: isLandscape ? 'contain' : 'cover',
          display: 'block',
          width: '100%',
          height: '100%',
          ...style,
        }}
        src={src}
        type={'video/mp4'}
        onCanPlay={handleCanPlay} // Set video as ready when it's ready to play
        onError={(error) => console.error('Video failed to load', error)}
      />

      {showControls && (
        <div className="control-wrapper">
          {!isPlaying && (
            <motion.button
              onClick={startVideo}
              className="play-button"
              whileHover={{ scale: 1.2, transition: { duration: 0.5 } }}
              whileTap={{ scale: 0.9, transition: { duration: 0.5 } }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                width="18px"
                height="18px"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </motion.button>
          )}
          {isPlaying && (
            <motion.button
              onClick={stopVideo}
              className="stop-button"
              whileHover={{ scale: 1.2, transition: { duration: 0.5 } }}
              whileTap={{ scale: 0.9, transition: { duration: 0.5 } }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                width="18px"
                height="18px"
              >
                <path d="M6 6h12v12H6z" />
              </svg>
            </motion.button>
          )}
        </div>
      )}
    </span>
  );
};
