import CocaCola from '../../assets/brands/CocaCola.png';
import BlackRuin from '../../assets/brands/blackbruin.png';
import GCC from '../../assets/brands/gcc.png';
import GranGrans from '../../assets/brands/gran_grans.png';
import Guepex from '../../assets/brands/guepex.png';
import MLK from '../../assets/brands/mlk.png';
import OneAgency from '../../assets/brands/one_agency.png';
import Pepsico from '../../assets/brands/pepsico.png';
import GuinnessStoreHouse from '../../assets/brands/guinness_storehoure.png';
import PullAndBear from '../../assets/brands/pull_and_bear.png';
import Three from '../../assets/brands/three.png';

const BRANDS_DATA = [
  { src: CocaCola, name: 'Coca Cola' },
  { src: GCC, name: 'GCC' },
  { src: BlackRuin, name: 'Black Ruin' },
  { src: GuinnessStoreHouse, name: 'Guinness Storehouse' },
  { src: GranGrans, name: 'Gran Grans' },
  { src: Guepex, name: 'Guepex' },
  { src: MLK, name: 'MLK' },
  { src: OneAgency, name: 'One Agency' },
  { src: Three, name: 'Three' },
  { src: PullAndBear, name: 'Pull And Bear' },
  { src: Pepsico, name: 'Pepsico' },
];

const TrustedBySection = () => {
  return (
    <section className="trusted-by-section">
      <div className="brand-title">Trusted by</div>
      <div className="brand-list-wrapper">
        {BRANDS_DATA.map((brand, index) => (
          <div className="brand-item" key={index}>
            <img src={brand.src} alt={brand.name} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default TrustedBySection;
