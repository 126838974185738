import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIsVisible } from '../../shared/useIsVisible';
import videoSrc from '../../assets/about.mov';
import posterSrc from '../../assets/about.png';
import './about.css';

const VideoIntro = () => {
  const videoContainerRef = useRef(null);
  const nameWrapperRef = useRef(null);

  const { isVisible, targetRef } = useIsVisible(
    {
      root: null,
      rootMargin: '200px',
      threshold: 0.1,
    },
    false
  );
  const videoRef = useRef(null);
  const startVideoOnMouseMove = useCallback(async () => {
    if (videoRef.current) {
      await videoRef.current.play();
    }
  }, [videoRef]);

  const stopVideoOnMove = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, [videoRef]);

  useEffect(() => {
    if (isVisible) {
      startVideoOnMouseMove();
    } else {
      stopVideoOnMove();
    }
  }, [isVisible, startVideoOnMouseMove, stopVideoOnMove]);

  useEffect(() => {
    const handleScroll = () => {
      const videoContainer = videoContainerRef.current;
      const nameWrapper = nameWrapperRef.current;
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;

      // Adjust the video container position and size based on scroll position
      if (scrollY < windowHeight) {
        videoContainer.style.top = `${15 - (scrollY / windowHeight) * 50}%`;
        nameWrapper.style.position = 'fixed';
        nameWrapper.style.top = '20%';
      } else {
        nameWrapper.style.position = 'absolute';
        nameWrapper.style.top = '4%';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [videoContainerRef, nameWrapperRef]);

  return (
    <div className="video-intro-container">
      <section className="video-intro-inner-content">
        <div className="name-wrapper" ref={nameWrapperRef}>
          <span>RAOUF</span>
          <span>FERKOUS</span>
        </div>
        <div className="video-container" ref={videoContainerRef}>
          <video
            className="intro-video"
            src={videoSrc}
            type="video/mov"
            poster={posterSrc}
            autoPlay
            preload="none"
            playsInline
            muted
            loop
            ref={targetRef}
          />
        </div>
      </section>
    </div>
  );
};

export default VideoIntro;
