import './portfolio.css';
import { motion, useScroll, useSpring } from 'framer-motion';
import VideoGallery from '../VideoGallery/index';

function Portfolio() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <div className="portfolio-wrapper">
      <div className="portfolio-content">
        <div className="portfolio-content-title">Portfolio</div>
        <div className="portfolio-content-paragraph">
          Creating videos is all about capturing emotion. It's crucial to
          embrace freedom and trust your artistic instincts. I let my passion
          for the craft lead the way. Those who take a deeper look at my work
          will catch a glimpse of my essence.
        </div>
      </div>
      <VideoGallery />
      <motion.div className="progress-bar" style={{ scaleX }} />
    </div>
  );
}

export default Portfolio;
