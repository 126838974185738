import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'; // Import Navigate for redirect
import Header from './components/header/index';
import PageTitle from './components/pageTitle';
import Home from './pages/home/index';
import About from './pages/about/index';
import Portfolio from './pages/Portfolio';
import './App.css';

const App = () => {
  return (
    <section className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/portfolio"
            element={
              <>
                <PageTitle title="🎥 Portfolio - Raouf Ferkous" />
                <Portfolio />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <PageTitle title="💭 About me - Raouf Ferkous" />
                <About />
              </>
            }
          />
          {/* Catch-all route to redirect any undefined path to the home page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </section>
  );
};

export default App;
