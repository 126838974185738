import {
  GCC_4 as GCC_4_video,
  GCC_2 as GCC_2_video,
  GGF_1 as GGF_1_video,
  GGF_7 as GGF_7_video,
  HTB_IIMTW as HTB_IIMTW_video,
  HTB_live as HTB_live_video,
  HTB_memories_1 as HTB_memories_1_video,
  HTB_proposal_teaser_2 as HTB_proposal_teaser_2_video,
  HTB_proposal_teaser_3 as HTB_proposal_teaser_3_video,
  HTB_scraps_in_the_bowl as HTB_scraps_in_the_bowl_video,
  MLK_client_testimonial as MLK_client_testimonial_video,
  MLK_client_testimonial_pauline as MLK_client_testimonial_pauline_video,
  MLK_showroom as MLK_showroom_video,
  all_plast as all_plast_video,
  baile_de_favela as baile_de_favela_video,
  black_bruin_commercial as black_bruin_commercial_video,
  boyne_hill_house_2 as boyne_hill_house_2_video,
  boyne_hill_house as boyne_hill_house_video,
  chaleur_commercial as chaleur_commercial_video,
  colossal_reel as colossal_reel_video,
  guepex as guepex_video,
  guinness_store_house as guinness_store_house_video,
  houseplant_happiness as houseplant_happiness_video,
  lastcall_careful as lastcall_careful_video,
  osaka as osaka_video,
  oshri_survive as oshri_survive_video,
  sinead as sinead_video,
  three_electric_picnic as three_electric_picnic_video,
} from '../../assets/videos/index';

import {
  GCC_4 as GCC_4_thumbnail,
  GCC_2 as GCC_2_thumbnail,
  GGF_1 as GGF_1_thumbnail,
  GGF_7 as GGF_7_thumbnail,
  HTB_IIMTW as HTB_IIMTW_thumbnail,
  HTB_live as HTB_live_thumbnail,
  HTB_memories_1 as HTB_memories_1_thumbnail,
  HTB_proposal_teaser_2 as HTB_proposal_teaser_2_thumbnail,
  HTB_proposal_teaser_3 as HTB_proposal_teaser_3_thumbnail,
  HTB_scraps_in_the_bowl as HTB_scraps_in_the_bowl_thumbnail,
  MLK_client_testimonial as MLK_client_testimonial_thumbnail,
  MLK_client_testimonial_pauline as MLK_client_testimonial_pauline_thumbnail,
  MLK_showroom as MLK_showroom_thumbnail,
  all_plast as all_plast_thumbnail,
  baile_de_favela as baile_de_favela_thumbnail,
  black_bruin_commercial as black_bruin_commercial_thumbnail,
  boyne_hill_house_2 as boyne_hill_house_2_thumbnail,
  boyne_hill_house as boyne_hill_house_thumbnail,
  chaleur_commercial as chaleur_commercial_thumbnail,
  colossal_reel as colossal_reel_thumbnail,
  guepex as guepex_thumbnail,
  guinness_store_house as guinness_store_house_thumbnail,
  houseplant_happiness as houseplant_happiness_thumbnail,
  lastcall_careful as lastcall_careful_thumbnail,
  osaka as osaka_thumbnail,
  oshri_survive as oshri_survive_thumbnail,
  sinead as sinead_thumbnail,
  three_electric_picnic as three_electric_picnic_thumbnail,
} from '../../assets/thumbnails/index';

export const ALL_VIDEOS = [
  {
    id: 'video1',
    title: 'Black bruin commercial',
    category: 'Commercial film',
    thumbnail: black_bruin_commercial_thumbnail,
    url: black_bruin_commercial_video,
    type: 'landscape',
  },
  {
    id: 'video2',
    title: 'Oshri - Survive',
    category: 'Music video',
    thumbnail: oshri_survive_thumbnail,
    url: oshri_survive_video,
    type: 'landscape',
  },
  {
    id: 'video3',
    title: 'Colossal Sportswear',
    category: 'Commercial Advert',
    thumbnail: colossal_reel_thumbnail,
    url: colossal_reel_video,
    type: 'portrait',
  },
  {
    id: 'video4',
    title: 'Galway City Council - Salthill',
    category: 'Social Advert',
    thumbnail: GCC_2_thumbnail,
    url: GCC_2_video,
    type: 'portrait',
  },
  {
    id: 'video5',
    title: 'Boyne Hill House 1',
    category: 'Commercial Advert',
    thumbnail: boyne_hill_house_thumbnail,
    url: boyne_hill_house_video,
    type: 'portrait',
  },
  {
    id: 'video6',
    title: 'Mark Lohan Kitchens - Client Testimonial',
    category: 'Spec Advert',
    thumbnail: MLK_client_testimonial_pauline_thumbnail,
    url: MLK_client_testimonial_pauline_video,
    type: 'landscape',
  },
  {
    id: 'video7',
    title: 'PlantHouse',
    category: 'Social Advert',
    thumbnail: houseplant_happiness_thumbnail,
    url: houseplant_happiness_video,
    type: 'landscape',
  },
  {
    id: 'video8',
    title: 'Hawketheband - Memories',
    category: 'Music video',
    thumbnail: HTB_memories_1_thumbnail,
    url: HTB_memories_1_video,
    type: 'portrait',
  },
  {
    id: 'video9',
    title: 'Hawketheband - Proposal 1',
    category: 'Music video',
    thumbnail: HTB_proposal_teaser_2_thumbnail,
    url: HTB_proposal_teaser_2_video,
    type: 'portrait',
  },
  {
    id: 'video10',
    title: 'Hawketheband - Proposal 2',
    category: 'Music video',
    thumbnail: HTB_proposal_teaser_3_thumbnail,
    url: HTB_proposal_teaser_3_video,
    type: 'portrait',
  },
  {
    id: 'video11',
    title: 'Chaleur',
    category: 'Commercial Advert',
    thumbnail: chaleur_commercial_thumbnail,
    url: chaleur_commercial_video,
    type: 'landscape',
  },
  {
    id: 'video12',
    title: 'Guepex',
    category: 'Commercial Advert',
    thumbnail: guepex_thumbnail,
    url: guepex_video,
    type: 'landscape',
  },
  {
    id: 'video13',
    title: 'Osaka',
    category: 'Social Advert',
    thumbnail: osaka_thumbnail,
    url: osaka_video,
    type: 'portrait',
  },
  {
    id: 'video14',
    title: 'Guinness Storehouse',
    category: 'Social Advert',
    thumbnail: guinness_store_house_thumbnail,
    url: guinness_store_house_video,
    type: 'portrait',
  },
  {
    id: 'video15',
    title: 'Baile de Favela',
    category: 'Event Video',
    thumbnail: baile_de_favela_thumbnail,
    url: baile_de_favela_video,
    type: 'portrait',
  },
  {
    id: 'video16',
    title: 'All Plast',
    category: 'Corporate Film',
    thumbnail: all_plast_thumbnail,
    url: all_plast_video,
    type: 'landscape',
  },
  {
    id: 'video17',
    title: 'Mark Lohan Kitchens - Galway Showroom',
    category: 'Corporate Film',
    thumbnail: MLK_showroom_thumbnail,
    url: MLK_showroom_video,
    type: 'landscape',
  },
  {
    id: 'video18',
    title: 'Gran Grans Food 1',
    category: 'Social Advert',
    thumbnail: GGF_7_thumbnail,
    url: GGF_7_video,
    type: 'portrait',
  },
  {
    id: 'video19',
    title: 'Boyne Hill House 2',
    category: 'Commercial Advert',
    thumbnail: boyne_hill_house_2_thumbnail,
    url: boyne_hill_house_2_video,
    type: 'portrait',
  },
  {
    id: 'video20',
    title: 'Mark Lohan Kitchens',
    category: 'Social Advert',
    thumbnail: MLK_client_testimonial_thumbnail,
    url: MLK_client_testimonial_video,
    type: 'portrait',
  },
  {
    id: 'video21',
    title: 'Poproyal - Careful',
    category: 'Music video',
    thumbnail: lastcall_careful_thumbnail,
    url: lastcall_careful_video,
    type: 'landscape',
  },
  {
    id: 'video22',
    title: 'Hawketheband - If I Made The World',
    category: 'Music video',
    thumbnail: HTB_IIMTW_thumbnail,
    url: HTB_IIMTW_video,
    type: 'landscape',
  },
  {
    id: 'video23',
    title: 'Three Electric Picnic',
    category: 'Event Video',
    thumbnail: three_electric_picnic_thumbnail,
    url: three_electric_picnic_video,
    type: 'portrait',
  },
  {
    id: 'video24',
    title: 'Galway City Council - All Finals',
    category: 'Social Advert',
    thumbnail: GCC_4_thumbnail,
    url: GCC_4_video,
    type: 'portrait',
  },
  {
    id: 'video25',
    title: 'Gran Grans Food 2',
    category: 'Social Advert',
    thumbnail: GGF_1_thumbnail,
    url: GGF_1_video,
    type: 'portrait',
  },
  {
    id: 'video26',
    title: 'Hawketheband - Live at Iveagh garden',
    category: 'Event Video',
    thumbnail: HTB_live_thumbnail,
    url: HTB_live_video,
    type: 'landscape',
  },
  {
    id: 'video27',
    title: 'Hawketheband - Scraps In The Bowl',
    category: 'Music video',
    thumbnail: HTB_scraps_in_the_bowl_thumbnail,
    url: HTB_scraps_in_the_bowl_video,
    type: 'portrait',
  },
  {
    id: 'video28',
    title: "The Irish Bloggers at Whelan's",
    category: 'Social Advert',
    thumbnail: sinead_thumbnail,
    url: sinead_video,
    type: 'portrait',
  },
];
