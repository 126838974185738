export const generateVideoRows = (videos) => {
  const rows = [];
  let currentLandscapeRow = [];
  let currentPortraitRow = [];

  for (let i = 0; i < videos.length; i++) {
    const video = videos[i];

    if (video.type === 'landscape') {
      currentLandscapeRow.push(video);

      // Push the row if we have 2 landscapes
      if (currentLandscapeRow.length === 2) {
        rows.push(currentLandscapeRow);
        currentLandscapeRow = [];
      }
    } else if (video.type === 'portrait') {
      currentPortraitRow.push(video);

      // Push the row if we have 3 portraits
      if (currentPortraitRow.length === 3) {
        rows.push(currentPortraitRow);
        currentPortraitRow = [];
      }
    }

    // Handle the last video
    if (i === videos.length - 1) {
      // Ensure any remaining videos are added to rows at the end
      if (currentLandscapeRow.length > 0) {
        rows.push(currentLandscapeRow);
        currentLandscapeRow = [];
      }
      if (currentPortraitRow.length > 0) {
        rows.push(currentPortraitRow);
        currentPortraitRow = [];
      }
    }
  }

  return rows;
};
