import React, { useEffect, useRef } from 'react';
import './about.css';
import VideoIntro from './VideoIntro';
import logo from '../../assets/logo.svg';

const Index = () => {
  return (
    <section className="about-wrapper">
      <VideoIntro />
      <div className="about-content">
        <div className="about-content-paragraph">
          Hello, I’m Raouf Ferkous based in Dublin, Ireland, and videography is
          more than just a profession to me — it’s my passion and my art. From a
          young age, I’ve been fascinated by the power of visual storytelling.
          What started as a hobby, filming family events and nature scenes, has
          evolved into a career where I get to do what I love every day.
        </div>
        <div className="about-content-paragraph">
          My journey into videography has taken me through a variety of
          experiences, from working on independent films to capturing the most
          intimate moments of weddings and the dynamic energy of corporate
          events. Each project has shaped me, honing my skills and deepening my
          understanding of how to tell a story that truly connects with an
          audience.
        </div>
        <div className="about-content-paragraph">
          I believe that every frame counts, and I’m committed to getting the
          details just right. My approach is personal—I invest time in
          understanding your vision and bringing it to life in a way that feels
          authentic and powerful. I’m not just behind the camera; I’m right
          there with you, making sure that every emotion, every moment, is
          captured in a way that you’ll treasure forever.
        </div>
        <div className="about-content-paragraph">
          Outside of videography, I’m an adventurer at heart. I draw inspiration
          from the world around me—whether it’s exploring new places, meeting
          new people, or simply observing the beauty in everyday life. These
          experiences fuel my creativity and influence the work I do.
        </div>
        <div className="about-content-paragraph">
          Let’s collaborate and create something extraordinary together.
        </div>
      </div>
      <div className="about-footer-wrapper">
        <img src={logo} className="about-footer" />
      </div>
    </section>
  );
};

export default Index;
