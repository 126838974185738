import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';
import logo from '../../assets/logo.svg';
import instagram from '../../assets/instagram.svg';

const INSTA_URL = 'https://www.instagram.com/raouf.ferkous/';

const Index = () => {
  const handleClickInsta = () => {
    window.open(INSTA_URL, '_blank');
  };
  return (
    <div className="header-wrapper">
      <img
        src={logo}
        className="header-logo"
        onClick={() => (window.location.href = '/')}
      />
      <div className="header-link">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/portfolio">Portfolio</Link>
        <div className="header-divider">|</div>
        <img
          src={instagram}
          className="header-insta"
          onClick={handleClickInsta}
        />
      </div>
      <div className="header-placeholder" />
    </div>
  );
};

export default Index;
