import { VideoComponent } from '../../components/video';
import { motion } from 'framer-motion';
import videoSrc from '../../assets/home-main-video.mov';
import videoPoster from '../../assets/home-main-video.png';
import './home.css';
import TrustedBySection from './TrustedBySection';

function Home() {
  return (
    <div className="Home">
      <div className="home-main-video-wrapper">
        <VideoComponent
          src={videoSrc}
          poster={videoPoster}
          showControls={false}
          isLandscape={false}
        />
        <div className="home-main-video-title">
          <div className="home-main-video-name">RAOUF FERKOUS</div>
          <hr className="home-main-video-divider" />
          <div className="home-main-video-occupation">Videographer</div>
          <div className="home-main-video-occupation">&</div>
          <div className="home-main-video-occupation">Filmmaker</div>
        </div>
        <span className="home-scroll-wrapper">
          <span className="scroll" />
        </span>
        <div className="home-scroll-text">Scroll down for more</div>
        <TrustedBySection />
      </div>
    </div>
  );
}

export default Home;
